import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let Comercial = class Comercial extends Vue {
    constructor() {
        super(...arguments);
        this.items = [
            {
                icon: 'campaign',
                title: 'Campanhas',
                subtitle: 'A primeira etapa para o comercial é o externo, neste menu você tem acesso às campanhas nacionais da franchising, acessando para visualizá-las individualmente, editar as informações, registrar ocorrências e tarefas, acompanhar a captação de cupons, além de gerar landing page e flyer para captação digital de cupons diretamente para o sistema drive.',
                chip: ['Cartaz', 'Flyer', 'Landing Page'],
                route: '/main/ComercialCampanhas/all',
            },
            {
                icon: 'call',
                title: 'CDA/SDR',
                subtitle: 'Os cupons já captados e distribuídos são destinados aos colaboradores responsáveis pela ligação, iniciando assim o agendamento. Cada colaborador tem acesso neste módulo aos cupons atribuídos a ele, organizados por ordem de prioridade para a ligação. Ao clicar, pode obter mais informações e registrar o resultado da ligação.',
                chip: ['Cupons', 'Métricas'],
                route: '/main/ComercialCDA/all',
            },
            {
                icon: 'confirmation_number',
                title: 'Cupons',
                subtitle: 'Neste módulo conseguimos registrar os cupons captados manualmente dentro do sistema, de maneira prática e ágil. Além de inserir os cupons no sistema, aqui você pode registrar ocorrências e tarefas, editar ou excluir cupons cadastrados incorretamente. Também é possível fazer a distribuição diária dos 30 cupons por colaborador e acompanhar o histórico de ligações de cada cupom.',
                chip: ['Por status', 'Por parceria', 'Por tipo', 'Por CDA'],
                route: '/main/ComercialCupons/all',
            },
            {
                icon: 'handshake',
                title: 'Parcerias',
                subtitle: 'A primeira etapa para o comercial é o externo. Neste menu, você tem acesso às parcerias negociadas pela sua unidade. Você pode registrar, acompanhar, gerenciar e editar essas parcerias. Dentro de cada parceria, é possível registrar ocorrências e tarefas, acompanhar a captação de cupons, além de criar landing pages e flyers para a captação digital de cupons diretamente no sistema drive.',
                chip: ['Criar parcerias', 'Ocorrências', 'Ações', 'Cupons', 'Divulgação'],
                route: '/main/ComercialParcerias/all',
            }
        ];
    }
    routeModule(item) {
        this.$router.push(item.route);
    }
};
Comercial = __decorate([
    Component
], Comercial);
export default Comercial;
